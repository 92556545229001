@use "sass:meta" as ---lcnhxkjbet;//This file is included in multiple places, so don't include actual CSS

@mixin articleGrid() {
	@include oGridContainer();
	max-width: 100%;
	display: flex;
	flex-direction: column;

	@include oGridRespondTo(L) {
		display: grid;
		grid-template-columns: 40px 40px minmax(500px, 700px) minmax(20px, 1fr) 300px;

		grid-template-areas: 'lhr . image . rhr-top'
			'lhr . content . rhr-top'
			'lhr . content . rhr-bottom'
			'. . tools . rhr-bottom'
			'more-articles more-articles more-articles more-articles more-articles'
			'. . onward . onward-rhr'
			'. . second-onward . onward-rhr'
			'. . promoted-content . .'
			'. . comments . comments-rhr';
		width: 100%;
	}

	@include oGridRespondTo(XL) {
		grid-template-columns: 40px 80px minmax(600px, 700px) minmax(20px, 1fr) 300px;
	}
}

@mixin articleGridCentered() {
	@include oGridRespondTo(L) {
		grid-template-columns: 40px 1fr minmax(500px, 700px) 1fr 40px;
	}

	@include oGridRespondTo(XL) {
		grid-template-columns: 1fr 40px minmax(500px, 700px) 40px 1fr;
	}
}

@mixin liveBlogArticleGrid() {
	@include oGridRespondTo(L) {
		grid-template-areas: 'lhr . content . rhr-top'
			'lhr . content . rhr-bottom'
			'. . comments . comments-rhr';
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:983");