@use "sass:meta" as ---lcnhxkjbet;.onward-journey--simple {
	@include oGridRespondTo(L) {
		width: 90%;
		margin: 0 auto;

		.o-teaser-collection__item:nth-child(n + 5) {
			margin-top: 24px;
		}
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:749");