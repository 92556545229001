@use "sass:meta" as ---lcnhxkjbet;@import '@financial-times/n-scrollytelling-image/main';

.n-content-layout--scrollytelling[data-layout-width='full-grid'] {
	z-index: 110; // place the scrollytelling component above the sticky nav
}

@include oGridRespondTo($until: M) {
	.n-content-layout--scrollytelling[data-layout-width='full-grid'] {
		width: 100vw;
		margin-left: -10px;
	}
}

@include oGridRespondTo($from: M) {
	.n-content-layout--scrollytelling[data-layout-width='full-grid'] {
		position: relative;
		width: 100vw;
		right: calc(50vw - 350px); // half the viewport width minus half the content column
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:1054");