@use "sass:meta" as ---lcnhxkjbet;.article-tools__buttons {
	display: inline-block;
	white-space: nowrap;
}

.article-tools__print,
.article-tools__comments,
.article-tools__reuse {
	@include oButtonsContent(
		$opts: (
			'size': 'big',
			'type': 'secondary',
			'theme': 'mono'
		)
	);
}

.article-tools__comments {
	margin-left: 8px;
}

.article-tools__print {
	@include oButtonsContent(
		$opts: (
			'icon': 'print',
			'size': 'big',
			'type': 'secondary',
			'theme': 'mono'
		)
	);
	margin-right: oSpacingByName('s2');
}

.n-content-copyright {
	padding-top: 0;
	margin: 0 oSpacingByName('s6') oSpacingByName('s3') 0;
	display: inline-block;

	@include oGridRespondTo($from: L) {
		margin-bottom: 0;
	}
}

.n-content-copyright__rights {
	white-space: nowrap;
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:984");