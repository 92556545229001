@use "sass:meta" as ---lcnhxkjbet;.onward-journey__items {
	padding-left: 0;

	@include oGridRespondTo($until: S) {
		margin-bottom: 0;
	}
}

.onward-journey__item {
	display: block;
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:748");