@use "sass:meta" as ---lcnhxkjbet;$system-code: 'next-article';
@import '@financial-times/o-loading/main';

@include oLoading();
@import '@financial-times/o-typography/main';
@import '@financial-times/o-buttons/main';
@import '@financial-times/o-icons/main';

.bullet-point-summary {
	background: oColorsByName('black-5');
	font-family: MetricWeb, sans-serif;
	padding: 0;
	margin-bottom: 20px;

	font-size: 18px;
	@media (min-width: 76.25em) {
		font-size: 20px;
	}
	#bullet-point-summary-bullet-list {
		margin-top: 16px !important;
		margin-bottom: 16px !important;
	}
	.summary-bar {
		background: oColorsByName('black-30');
		padding: 12px 16px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-height: 40px;
	}

	.summary-bar.collapsed {
		@media (max-width: 400px) {
			/* things in the bar are centred for mobile until the 'Summary' label text
			is displayed (ie. component uncollapsed)
			*/
			justify-content: center;
		}
		.summary-label {
			@media (max-width: 400px) {
				display: none; // hide the label on mobile until the component is expanded to show the actual summary
			}
		}
	}

	.summary-label {
		font-weight: bold;
		padding-top: 4px;
		display: block;
	}

	.loading-section {
		// otherwise the bar expands slightly in the phase change
		width: 24px;
		height: 24px;
	}

	.revealed-section {
		margin: 0;
		padding: 16px;
		padding-top: 0;
	}

	#info-symbol {
		background-image: url(https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1:info?source=pro-ask-ft&format=svg);
		width: 40px;
		height: 40px;
		vertical-align: middle;
		margin-right: -5px;
	}

	#disclaimer {
		vertical-align: middle;
		font-size: 16px;
		padding-left: 6px;
		padding-bottom: 22px;
	}

	.byline {
		font-weight: 600;
	}

	.feedback-button {
		@include oButtonsContent(
			$opts: (
				'type': 'secondary',
				'theme': 'mono'
			)
		);
	}

	.feedback-button-big {
		@include oButtonsContent(
			$opts: (
				'type': 'secondary',
				'theme': 'mono',
				'size': 'big'
			)
		);
	}

	.helpful-yes {
		margin-right: 10px;
		margin-left: 10px;
	}

	#bullet-point-summary-feedback {
		background: oColorsByName('black-10');
		padding: 16px;
		font-size: 16px;
		min-height: 29px;
	}

	.o-forms-input--checkbox.container {
		display: flex;
		flex-flow: row wrap;
		justify-content: left;
		column-gap: 20px;
	}

	/*
the fancy animated button
*/
	.cta-section {
		@media (max-width: 400px) {
			width: 100%;
		}
		.show-summary-button {
			position: relative;
			overflow: hidden;
			background-size: 40px 40px;
			padding-left: 40px;
			background-repeat: no-repeat;
			background-position-x: 4px;
			background-image: url(https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1:sparkles?source=pro-ask-ft&tint=%23FFFFFF,%23FFFFFF&format=svg);
			@media (max-width: 400px) {
				width: 100%;
			}
		}

		.show-summary-button::after {
			content: '';
			position: absolute;
			bottom: -1px;
			left: 0;
			height: 4px; /* Adjust the thickness of the border */
			width: 100%;
			background: linear-gradient(to right, white, transparent);
			transform: translateX(-100%);
		}

		.icon {
			width: 40px;
			height: 40px;
			margin-right: 10px;
		}

		.text {
			position: relative;
			color: transparent;
			background: linear-gradient(90deg, white, white 50%, teal 50%);
			-webkit-background-clip: text;
			background-clip: text;
			background-size: 200%;
			display: inline-block;
		}

		// trigger the animations when the relevant element is visible
		.visible {
			.text {
				animation: fadeInText 1s forwards;
			}
		}
		.show-summary-button.visible::after {
			animation: borderAnimation 1.2s ease-out forwards;
		}

		@keyframes fadeInText {
			0% {
				background-position: 100% 0;
				opacity: 0;
			}
			100% {
				background-position: 0 0;
				opacity: 1;
			}
		}

		@keyframes borderAnimation {
			0% {
				transform: translateX(-100%);
			}
			50% {
				transform: translateX(-50%);
				opacity: 1;
			}
			100% {
				transform: translateX(0);
				opacity: 0;
			}
		}
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:1150");