@use "sass:meta" as ---lcnhxkjbet;@import '@financial-times/o-editorial-typography/main';

$article-icon-size: 44;

.article-print {
	background: inherit;
	border: 0;
	padding: 0;
	&:before {
		@include oIconsContent(
			'print',
			oColorsByName('black'),
			$article-icon-size,
			$iconset-version: 1
		);
	}
}

.article-comment-count {
	visibility: hidden;
	white-space: nowrap;
	&:before {
		@include oIconsContent(
			'speech',
			oColorsByName('black'),
			$article-icon-size,
			$iconset-version: 1
		);
	}
}

.article-comment-count,
.article-print {
	@include oTypographySans(0, $line-height: 28px, $weight: 'semibold');
	flex: 0 0 auto;
	max-height: $article-icon-size * 1px;
	margin-left: 0;
	color: oColorsByName('black');
	position: relative;
	border-bottom-width: 0;
	vertical-align: top;
	&:before {
		content: '';
		vertical-align: middle;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:950");