@use "sass:meta" as ---lcnhxkjbet;@import '@financial-times/o-editorial-typography/main';

.article__content-sign-up {
	border-bottom: 1px solid oColorsByName('black-20');
	margin-bottom: oSpacingByName('s4');

	.article__content-sign-up-topic-title {
		@include oEditorialTypographyByline();
		font-size: oSpacingByIncrement(5);
		line-height: oSpacingByName('s6');
		font-weight: bold;
	}

	.article__content-sign-up-topic-description {
		@include oEditorialTypographyByline();
		font-size: oSpacingByName('s4');
		line-height: oSpacingByIncrement(5);
	}

	.article__content-sign-up-iframe {
		overflow: hidden;
		width: -webkit-fill-available;
		border: 0;
		width: 100%;
	}
}

.o-message--sign-up-additional-location {
	.article__content-sign-up-topic-description {
		@include oGridRespondTo($from: L) {
			max-width: 700px;
		}
	}

	.sign-up-additional-location-container {
		overflow-y: auto;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:1051");