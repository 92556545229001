@use "sass:meta" as ---lcnhxkjbet;// Contains grid container setup, and positioning of elements within the grid.

@import 'helpers';

.article-content {
	display: grid;
	grid-template-columns: 100%;
	grid-template-areas: 'topper'
		'article-body';

	// grid display stops margins from collapsing, so this removes the margin when we are in grid display
	// note that we are overriding default o-topper styles here, just because we put this in a grid, but have to maintain a workaround for topper package to keep the margin there.
	// we cannot avoid being tightly bound to o-topper styles which is brittle. ideally the o-topper would not rely on things like collapsing margins and leave spacing to the containers.
	.o-topper:not(.o-topper--package) {
		margin-bottom: 0;
	}
}

.article-grid {
	grid-area: article-body;

	@include articleGrid();
	margin: 20px auto;

	.main-image {
		grid-area: image;
	}

	.share-nav {
		grid-area: lhr;
		z-index: 7;
	}

	.article__content {
		margin-bottom: oSpacingByName('s6');
		grid-area: content;
		max-width: 700px;
		width: 100%;
		position: relative; //so scroll depth tracking works
		@supports (display: grid) {
			max-width: auto;
		}
	}

	// podcast body uses this
	.article__content-body {
		@include oNormaliseClearfix();
	}

	.article__right {
		grid-area: rhr-top;
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-direction: column;

		// Create a stacking context to prevent ad content from setting a z-index that overlays our content
		position: relative;
		z-index: 1;

		> .sidebar-advert {
			margin-bottom: oSpacingByName('s6');
		}

		> .sidebar-advert.o-ads-sticky {
			// HACK: @supports (position: sticky) is misreported by MSEdge
			// @supports (-webkit-appearance:none) allows us to target the browsers
			// that do support it more accurately
			// Further details:
			// <https://github.com/Financial-Times/next-article/pull/3466>
			@supports (-webkit-appearance: none) {
				position: sticky;
				top: 80px;
			}
		}

		@include oGridRespondTo($until: L) {
			display: none;
		}
	}

	.article-tools {
		margin-bottom: oSpacingByName('s8');
		padding: oSpacingByName('s6') 0 oSpacingByName('s6') 0;
		grid-area: tools;
		border-bottom: 1px solid #ccc1b7;
		border-top: 1px solid #ccc1b7;

		@include oGridRespondTo($from: M) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.email-article-container {
		margin-bottom: oSpacingByName('s6');
		margin-top: oSpacingByName('s6');
		grid-column: span 2;
	}

	.onward-journey--simple .onward-journey__content {
		grid-area: onward;
	}

	.onward-journey--package {
		grid-area: onward;
	}

	.onward-journey--simple {
		grid-row: 6;
		grid-column: 1 / span 5;
	}

	.comments-container {
		grid-area: comments;
		margin-bottom: oSpacingByName('s6');
		margin-top: oSpacingByName('s4');
	}

	.comments-rhr {
		grid-area: comments-rhr;

		display: none;
		position: relative;

		@include oGridRespondTo($from: L) {
			display: block;
			box-sizing: border-box;
			padding-top: 180px;
		}
	}

	.promoted-content__container {
		grid-area: promoted-content;
	}

	.bottom-advert {
		@include oGridRespondTo($until: L) {
			display: none;
		}
	}

	.show-in-large-and-above {
		@include oGridRespondTo($until: L) {
			display: none;
		}
	}

	.ad-rhr-bottom {
		display: flex;
		flex: 1;
		align-items: flex-end;
		padding-bottom: 60px;
	}

	@mixin tintedBackgroundBefore() {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			background: oColorsByName('black-5');
			width: 100vw;
			margin-left: -50vw;
			left: 50%;
			height: 100%;
			z-index: -1;
		}
	}

	.article__right-bottom {
		grid-area: rhr-bottom;
		align-self: stretch;
		z-index: 1; // grid & flex items both support `z-index` directly

		display: flex;
		flex-direction: column;

		padding: 0;

		@include oGridRespondTo(L) {
			align-self: start;
		}

		.article-grid--centered & {
			@include oGridRespondTo(L) {
				@include tintedBackgroundBefore();
			}
		}
	}

	// Increased specificity to fix IE11 issue where generated CSS was targetting incorrect elements
	&.article-grid--centered,
	&.article-grid--full-width-graphics {
		> .article__right-bottom {
			grid-area: second-onward;
			padding: 0;
		}
	}
}

.article-grid--centered {
	@include articleGridCentered();
	.article__right,
	.bottom-advert,
	.onward-myft {
		display: none;
	}
}

.hidden {
	display: none !important;
}

.article-grid--live-blog {
	@include liveBlogArticleGrid();
	padding-left: 0;
	padding-right: 0;

	// breakpoint at 700px as this is the article content max-width and avoids a gap on the right
	@media (min-width: 700px) {
		padding-left: oSpacingByName('s4');
		padding-right: oSpacingByName('s4');
	}

	.share-nav,
	.article-info {
		padding-left: 10px;
		padding-right: 10px;
	}
}

[data-feature-ads-pg='on'] {
	&.article-grid {
		grid-template-areas: 'lhr . image            . rhr-top'
			'lhr . content          . rhr-top'
			'lhr . content          . rhr-top'
			'.   . tools            . rhr-top'
			'more-articles more-articles more-articles more-articles more-articles'
			'.   . onward           . onward-rhr'
			'.   . second-onward    . onward-rhr'
			'.   . promoted-content . .'
			'.   . comments         . comments-rhr';
	}

	&.article-grid--live-blog {
		grid-template-areas: 'lhr . content  . rhr-top'
			'lhr . content  . rhr-top'
			'.   . comments . comments-rhr';
	}

	.article-content__concepts {
		grid-area: second-onward;

		// The Concept List is cloned to the RHR, so hide the original
		// (rendered below the article body) at desktop breakpoints
		& .concept-list.cloned-to-rhr {
			@include oGridRespondTo(L) {
				display: none;
			}
		}
	}

	.comments-rhr {
		padding: 0;
		margin-top: 180px;
	}

	// Give the after-content slot the same margin as the article body
	.pg-slot[pos='mid3'].pg-slot--loaded {
		@include oGridRespondTo($until: L) {
			margin-block-end: 40px;
		}
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:982");