@use "sass:meta" as ---lcnhxkjbet;$system-code: 'next-article';
@import '@financial-times/o-loading/main';

@include oLoading();
@import '@financial-times/o-typography/main';
@import '@financial-times/o-icons/main';
@import '@financial-times/o-colors/main';

.discussion-question {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	padding: 32px 0;
}

.discussion-question__title {
	display: block;
	padding: 4px 8px;
	max-width: fit-content;
	max-height: fit-content;
	background: oColorsByName('wheat');
}

.discussion-question__title p {
	@include oTypographySans(-1, $line-height: 20px);
	text-transform: uppercase;
	margin: 0;
}

.discussion-question .discussion-question__body {
	@include oTypographySans($scale: 2, $weight: 'bold', $line-height: 28px);
	margin-bottom: 16px !important;
}

.discussion-question__cta {
	display: flex;
	align-items: center;
}

.discussion-question__comments-icon {
	@include oIconsContent(
		$icon-name: 'speech',
		$color: oColorsByName('black'),
		$size: 32
	);
	margin-left: -8px;
	margin-right: -4px;
}

.discussion-question__cta .discussion-question__cta__link {
	@include oTypographyLink();
	@include oTypographySans(1);
}

@media (min-width: 30.625em) {
	.n-content-body > .discussion-question__container {
		// copied from n-content-pullquote component
		display: block;
		flex-basis: 41.6666666667%;
		min-width: 41.6666666667%;
		max-width: 41.6666666667%;
		margin-right: 32px;
		float: left;
		clear: both;
	}
}

@media print {
	.discussion-question__container {
		display: none;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:1151");