@use "sass:meta" as ---lcnhxkjbet;@import '@financial-times/o-share/main';

@include oShare(
	$opts: (
		'vertical': true,
		'inverse': true,
		'icons': (
			'x',
			'facebook',
			'whatsapp',
			'linkedin',
			'share',
			'corporate'
		)
	)
);

.share-nav {
	position: relative;

	.o-tooltip {
		color: oColorsByName('black');
		width: 250px;
	}

	.o-tooltip-close {
		// Override this being set in n-ui-foundations for all links.
		border-bottom: 0;
	}
	.o-tooltip-content {
		text-align: left;
		@include oTypographySans($scale: 0);
	}
}

// There are two share navs on the article page,
// one horizontal and one vertical. Display only one
// depending on the viewport size.
.share-nav__vertical {
	display: none;
}
@include oGridRespondTo($from: L) {
	// Show the vertical share bar from the large breakpoint.
	// Unless the share nav overlaps some content immediately
	// before scroll, in which case we always want to show the
	// horizontal nav.
	.share-nav__vertical {
		position: sticky;
		display: block;
		top: 80px;
		z-index: 7;
	}
	// Hide the horizontal share bar from the large breakpoint.
	// Unless the share nav overlaps some content immediately
	// before scroll, in which case we always want to show the
	// horizontal nav.
	.share-nav__horizontal {
		display: none;
	}
}

// Hide the share action when JavaScript is unavailable.
.core .share-nav .share-nav__share-action {
	display: none;
}

// Hide the Whatsapp action on larger viewports.
.share-nav .share-nav__whatsapp-action {
	@include oGridRespondTo(S) {
		display: none;
	}
}

// n-myft-ui save-button: update layout to work within o-share :mask:
// The n-myft-ui save-button has a fixed height with an absolutely positioned
// label which overflows. So the icon copy takes up space in its container
// remove the absolute positioning and add padding to the icon element, so the
// copy does not overlap the icon background image.
.share-nav__save-action .n-myft-ui__save-button-with-icon:not([disabled]) {
	$text-color: oColorsByUsecase('o-share/ft-icon', 'text');
	$text-color-urlencoded: str-slice(ie-hex-str($text-color), 4);
	&:focus,
	&:hover {
		color: $text-color;
		background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1:bookmark-outline?format=svg&source=#{$system-code}&tint=#{$text-color-urlencoded}');
		background-color: oColorsByUsecase('o-share/ft-icon', 'background');
		border-color: oColorsByUsecase('o-share/ft-icon', 'border');
	}

	&[aria-pressed='true']:focus,
	&[aria-pressed='true']:hover {
		background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1:bookmark?format=svg&source=#{$system-code}&tint=#{$text-color-urlencoded}');
	}
}

.share-nav__save-action .n-myft-ui__save-button-with-icon {
	border: $o-share-border-size $o-share-border-style
		oColorsByUsecase('o-share/default-icon', 'border');
	.save-button-with-icon-copy {
		position: static;
	}
}

.share-nav__horizontal .share-nav__save-action .save-button-with-icon-copy {
	// correct n-myft-ui save-button copy size when in a horizonal share nav
	@include oTypographySans($scale: -1, $include-font-family: false);
}

.share-nav__save-action .n-myft-ui__save-button-with-icon,
.share-nav__save-action .n-myft-ui__save-button-with-icon:not([disabled]) {
	// fticons SVG icons align to a 40x40 pixel grid, with 10px padding
	// built in on each side. Used to match the icon's inherent margin on the text.
	// https://github.com/Financial-Times/fticons/blob/master/contributing.md#design
	$icon-whitespace-ratio: 10/40;
	$icon-whitespace: $icon-whitespace-ratio * $o-share-icon-size;
	// These selectors use the `@at-root` Sass selector to target the n-myft-ui
	// icon depending on if it is with a vertical or horizontal share nav.
	@at-root .share-nav__vertical & {
		height: auto;
		width: $o-share-icon-size;
		padding: ($o-share-icon-size - $icon-whitespace) 0 oSpacingByName('s1');
		margin: 0 0 -#{$o-share-border-size};
	}
	@at-root .share-nav__horizontal & {
		width: auto;
		height: $o-share-icon-size;
		min-height: $o-share-icon-size; // overwrite n-myft-ui min-height
		padding-left: calc(#{$o-share-icon-size} - #{$o-share-border-size * 2});
		padding-right: $icon-whitespace;
		margin: 0 #{-$o-share-border-size} 0 0;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:684");