@use "sass:meta" as ---lcnhxkjbet;$o-ads-is-silent: false !default;
@import '@financial-times/ads-legacy-o-ads/main';

// Ad breakpoint where leaderboards start working
$ad-medium-breakpoint: 760px;

[data-config-key='leaderboard'] {
	display: none;

	@media (min-width: $ad-medium-breakpoint) {
		display: block;
		min-height: 90px;
	}
}

.advert--mini-billboard {
	display: none;

	@include oGridRespondTo($until: M) {
		padding: 0;
		display: block;
		line-height: 0;
		text-align: center;

		.in-article-advert,
		.mobile-advert {
			&.o-ads--empty,
			&[data-o-ads-loaded='false'] {
				height: 0;
				min-height: 0;
				padding: 0;
			}
		}
	}
}

.sidebar-advert {
	display: none;

	@include oGridRespondTo(L) {
		display: block;
	}

	&.sidebar-advert--hidden,
	&.sidebar-advert--no-responsive[data-o-ads-master-loaded='Responsive'] {
		display: none;
	}
}

// If no fullbleed images RHR ads have `position: static`, so use a margin to space them
.sidebar-advert + .sidebar-advert {
	margin-top: 300px;
}

// prevent two ads appearing next to eachother in RHR
.sidebar-advert + .bottom-advert {
	margin-top: 100vh;
}

.fake-rhr-advert {
	left: calc(100% + 60px);
	position: absolute;
}

.in-content-advert {
	width: max-content;
}

.in-article-advert {
	visibility: hidden;
	height: 0;
	width: 0;
	overflow: hidden;

	@include oGridRespondTo($until: L) {
		@include oAdsReserve90();

		visibility: visible;
		height: auto !important; /* bugfix */
		width: auto;
		clear: both;
		margin-bottom: 30px;
		padding-bottom: 20px;
		border-bottom: 1px solid;

		.o-ads__outer {
			padding-bottom: 8px;
		}
	}

	&[data-o-ads-loaded='Responsive'][data-o-ads-master-loaded='Responsive'] {
		visibility: visible;
		height: auto;
		width: auto;
		clear: both;
	}
}

.o-ads--label-left .o-ads__inner {
	padding-top: 20px;
	&:before {
		@include oTypographySans(-1);
		content: 'Advertisement';
		display: block;
		text-align: left;
		border-top: 1px solid;
		position: relative;
		top: -20px;
		padding-top: 24px;
	}
}

.native-ad {
	display: none;
}

.native-ad--border {
	border: 1px solid oColorsByName('black-20');
}

.native-ad--loaded {
	display: block;
}

.native-ad__fullwidth {
	padding-bottom: 10px;
}
.native-ad__package
	.o-teaser--small.o-teaser--stacked
	.o-teaser__image-container {
	width: 175px;
	float: left;
}
.native-ad__package .o-teaser__content {
	padding: 0 0 12px 10px;
	float: right;
	width: 300px;
}
.native-ad__package .o-teaser--small.o-teaser--stacked {
	flex-direction: row;
}

// Lower RHR ads
// -----------------------------------------------------------------------------
.rhr__ads--lower {
	grid-area: rhr-bottom;
	position: relative;
}

.sidebar-advert.sidebar-advert--fullbleed {
	position: absolute;
	margin: 0;
}

// Improve ads slot collapse (Hide slot when slot is 1x1 except header)
// ------------------------------------------------

[data-o-ads-loaded='OneByOne']:not(.above-header-advert) {
	display: none;
}

// RHR
// -----------------------------------------------------------------------------
.rhr-region {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

// Add .debug to the :root element's classlist to visually check RHR slots
.debug {
	& .article__right {
		background: lightsteelblue;
	}

	& .rhr__ads--lower {
		background: pink;
	}

	& .sidebar-advert {
		background: lightgreen;
		opacity: 0.5;
	}

	& [data-layout-width='full-grid'] {
		opacity: 0.5;
	}
}

.advert--watch {
	transition: visibility 0.5s, opacity 0.5s;
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:805");