@use "sass:meta" as ---lcnhxkjbet;.feedback {
	@include oTypographySans($scale: -1, $include-font-family: false);

	.o-forms {
		padding: 0;
	}

	.o-tooltip-content {
		padding: 15px 20px;
	}

	.o-tooltip-close {
		border-bottom: 0;
	}

	.o-forms__textarea {
		min-width: 270px;
		min-height: oSpacingByName('l24');
	}

	.feedback__heading {
		@include oTypographySans($scale: 0, $include-font-family: false);

		> small {
			color: oColorsByName('black-60');
			@include oTypographySans($scale: -1);
			font-weight: 400;
		}
	}
}

.feedback__invitation {
	@include oTypographySans(-1);
}

.feedback__trigger {
	.feedback--complete & {
		display: none;
	}
}

.feedback__complete {
	display: none;

	.feedback--complete & {
		@include oTypographySans($weight: 'semibold');
		display: inline;
	}
}

.feedback__thumb-up,
.feedback__thumb-down {
	@include oIconsContentBaseStyles;
	background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fs3-eu-west-1.amazonaws.com%2Fft-next-assets-prod%2Fassets%2Faudio%2Fthumb-down.svg?source=next&tint=%2300757F&format=svg');
	width: 32px;
	height: 32px;
}

.feedback__thumb-up {
	background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fs3-eu-west-1.amazonaws.com%2Fft-next-assets-prod%2Fassets%2Faudio%2Fthumb-up.svg?source=next&tint=%2300757F&format=svg');
}

.js-feedback__content {
	display: none;
}

.feedback__options {
	list-style: none;
	margin: 0;
	padding: 0;
}

.feedback__option-btn {
	@include oTypographySans($scale: 0);
	background: none;
	border: 0;
	color: oColorsByName('black');
	padding: oSpacingByName('s1') 5px;
	text-align: left;
	display: flex;
	align-items: center;
	min-width: 270px;
	transition-property: background-color, color;
	transition-duration: 0.1s;

	&:hover,
	&:focus {
		background: oColorsByName('teal');
		color: oColorsByName('white');

		.feedback__thumb-down {
			background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fs3-eu-west-1.amazonaws.com%2Fft-next-assets-prod%2Fassets%2Faudio%2Fthumb-down.svg?source=next&tint=%23ffffff&format=svg');
		}

		.feedback__thumb-up {
			background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fs3-eu-west-1.amazonaws.com%2Fft-next-assets-prod%2Fassets%2Faudio%2Fthumb-up.svg?source=next&tint=%23ffffff&format=svg');
		}
	}

	> i {
		margin-right: 10px;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:411");