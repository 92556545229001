$o-grid-mode: 'snappy';
$system-code: 'next-article';

@import 'n-ui-foundations/mixins';

@import 'components/feedback/main';
@import 'components/gift-article/main';
$n-notification-is-silent: false;
@import '@financial-times/n-notification/main';
@import 'components/share/main';
@import 'components/teasers/main';
@import 'components/onward-journey/head';
@import 'components/onward-journey/lazy';
@import 'components/myft-tags/main';
@import '@financial-times/n-myft-ui/components/concept-list/main';
@import 'components/hide-when-overlapped/main';
@import '@financial-times/n-myft-ui/components/follow-button/main';
@import '@financial-times/n-myft-ui/myft/main';
@import 'components/progress-indicator/main';
@import 'components/widgets-config/main.scss';
@import 'components/workspace-teaser/main.scss';

// Ads
@import 'components/ads/main';
@import 'components/ads/head';

// must include here, not lazy loaded, as used by intersection observer
@import 'components/comments/main';

@import '@financial-times/o-buttons/main';
$o-spacing-is-silent: true;
@import '@financial-times/o-spacing/main';
@import '@financial-times/o-tooltip/main';

// HACK: n-content-body _should not_ include n-ui-foundations
$n-ui-foundations-is-silent: true;
@import '@financial-times/cp-content-pipeline-styles/main';

$o-overlay-is-silent: false;
@import '@financial-times/o-overlay/main';

$o-expander-is-silent: false;
@import '@financial-times/o-expander/main';

$o-lazy-load-is-silent: false;
@import '@financial-times/o-lazy-load/main';

@import 'components/article/main';
@import 'components/classifier/main';
@import 'components/table/main';
@import 'components/tags/main';
@import 'components/topper/main';
@import 'components/grid/article';
@import 'components/tools/main';
@import 'components/video/main';

@import '@financial-times/n-myft-ui/components/button/main';
@import 'components/pre-body-promotional-banner/instant-alert-promo';
@import 'components/pre-body-promotional-banner/banner-cta';
@import '@financial-times/n-magnet/main';
@import '@financial-times/n-syndication/main';
@import 'components/sign-up/confirmation';
@import 'components/sign-up/details';
@import 'components/flourish/main';
@import 'components/scrollytelling/main';

@import '@financial-times/n-exponea/main';
@include nExponea();
// these import statements must come after the others to ensure messaging styles aren't overridden
// related: https://github.com/Financial-Times/next-article/pull/3755
@import '@financial-times/n-messaging-client/critical';
@import '@financial-times/n-messaging-client/main';
@import '@financial-times/cp-content-pipeline-ui/src/components/content-tree/Clip/client/main';

@import 'components/article/ab-test-bullet-points/main';
@import 'components/article/discussion-question/main';

// this needs to be imported this low for the styles to render correctly
@import 'components/onward-journey/free-to-read-experiment';

.article-big-button {
	@include oButtonsContent(
		$opts: (
			'size': 'big',
			'type': 'primary'
		)
	);
}

.article--full-width-background {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: oColorsByName('black-5');
		width: 100vw;
		margin-left: -50vw;
		left: 50%;
		height: 100%;
		top: 0;
		z-index: -1;
	}
}

.o-topper__opinion-genre {
	display: inline !important;
}

.o-expander__content--expanded-next-article {
	max-height: inherit;
}

// The print styles should be imported last to override screen styles
@import 'print';
