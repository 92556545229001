@use "sass:meta" as ---lcnhxkjbet;@import '@financial-times/o-typography/main';
@import '@financial-times/o-icons/main';
@include oIcons();
@import '@financial-times/o-spacing/main';
@include oSpacing();
@import '@financial-times/o-colors/main';
@include oColors();

.workspace-teaser__cta.o-buttons.o-buttons--primary.o-buttons--big.o-buttons--professional {
	display: flex;
	align-items: center;
	padding: oSpacingByName('s2') oSpacingByName('s3');
}

.workspace-teaser {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	gap: oSpacingByName('s3');
	align-items: flex-start;
	width: 100%;
	padding: oSpacingByName('s3');
	background-color: oColorsByName('white-40');

	&__description {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;

		&-title {
			margin: 0;
			margin-right: oSpacingByName('s6');
			color: oColorsByName('black-80');
			@include oTypographySans($scale: 0, $weight: 'semibold');
		}

		&-close {
			cursor: pointer;
			border: unset;
			@include oIconsContent($icon-name: 'cross', $size: 36);
		}
	}

	&__cta {
		&-arrow-icon {
			@include oIconsContent(
				$icon-name: 'arrow-right',
				$size: 24,
				$color: oColorsByName('white')
			);
		}
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:801");