@use "sass:meta" as ---lcnhxkjbet;$progress-indicator-size: 40px;
$progress-indicator-padding: 2px;
$progress-indicator-bar-width: 2px;
$progress-indicator-slice-and-bar-size: $progress-indicator-size -
	($progress-indicator-padding * 2);

.progress-indicator__container {
	margin: 0 10px;
	display: none;
	min-height: 80px;
	width: $progress-indicator-size;
	@include oGridRespondTo(M) {
		display: inline-block;
	}
	@include oGridRespondTo(L) {
		margin: 0;
		position: absolute;
		left: -40px - $progress-indicator-size;
	}
	@include oGridRespondTo(XL) {
		left: -80px - $progress-indicator-size;
	}
}

.progress-indicator--no-progress {
	display: none;
}

.share-nav {
	@include oGridRespondTo(L) {
		.progress-indicator__container {
			position: static;
			margin: 10px 0 0;
			display: block;
		}

		.progress-indicator--no-progress {
			opacity: 0;
		}

		.progress-indicator--in-progress {
			opacity: 1;
		}

		.progress-indicator--no-progress,
		.progress-indicator--in-progress {
			display: block;
			background-color: oColorsByName('wheat');

			.progress-indicator__overlay {
				background-color: oColorsByName('wheat');
			}

			.progress-indicator__bar {
				background-color: oColorsByName('claret');
			}

			.progress-indicator__icon--complete {
				@include oIconsContent('tick', oColorsByName('claret'), 100%);
			}
		}
	}
}

.progress-indicator {
	width: $progress-indicator-size;
	height: $progress-indicator-size;
	border-radius: 50%;
	position: relative;
	opacity: 1;
	transition: background-color 500ms, opacity 500ms;
	background-color: oColorsByName('claret');
}

.progress-indicator__overlay {
	background-color: oColorsByName('claret');
	width: calc(100% - (2 * #{$progress-indicator-bar-width + $progress-indicator-padding}));
	height: calc(100% - (2 * #{$progress-indicator-bar-width + $progress-indicator-padding}));
	position: absolute;
	left: $progress-indicator-bar-width + $progress-indicator-padding;
	top: $progress-indicator-bar-width + $progress-indicator-padding;
	border-radius: 50%;
	transition: background-color 500ms, color 500ms;
}

.progress-indicator__label {
	@include oTypographySans(-2);
	text-align: center;
	display: block;
	margin: 5px auto;
}

.progress-indicator__icon--complete {
	@include oIconsContent('tick', oColorsByName('white'), 100%);
}

.progress-indicator__slice,
.progress-indicator__bar {
	width: $progress-indicator-slice-and-bar-size;
	height: $progress-indicator-slice-and-bar-size;
	position: absolute;
	border-radius: 50%;
	transition: transform 250ms linear;
}

.progress-indicator__slice {
	top: $progress-indicator-padding;
	left: $progress-indicator-padding;
	clip: rect(0, $progress-indicator-slice-and-bar-size, $progress-indicator-slice-and-bar-size, $progress-indicator-slice-and-bar-size/2);
}

.progress-indicator__bar {
	clip: rect(0, $progress-indicator-slice-and-bar-size/2, $progress-indicator-slice-and-bar-size, 0);
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:799");