@use "sass:meta" as ---lcnhxkjbet;@include oGridRespondTo('L') {
	.data-overlap-initialised {
		transition: opacity 0.15s ease-in, visibility 0.15s ease-in;
		opacity: 0;
		z-index: -1; // don't interfere with the overlapping content
		visibility: hidden;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:752");