@use "sass:meta" as ---lcnhxkjbet;.myft-tags__intro {
	padding-top: 0;
	padding-bottom: oSpacingByName('s4');
	margin-top: oSpacingByName('s4');
	margin-bottom: 0;
	border-bottom: 1px solid oColorsByName('black-20');
	> p {
		margin: 0;
	}
}

.myft-tags__list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.myft-tags__list-item {
	padding-top: oSpacingByName('s3');
	padding-bottom: oSpacingByName('s3');
	padding-right: 110px;
	position: relative;
	border-bottom: 1px solid oColorsByName('black-20');
	min-height: 26px;

	> .n-myft-ui {
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -13px;
	}
}

.myft-tags__product-tour {
	padding: oSpacingByName('s4');
	text-align: center;
}

.myft-tags__product-tour-link {
	@include oTypographyLink;
	@include oTypographySans($scale: 0);
	position: relative;

	&:after {
		@include oIconsContent(
			'arrow-right',
			oColorsByName('teal-40'),
			20,
			$iconset-version: 1
		);
		content: '';
		position: absolute;
		top: -1px;
		right: -20px;
	}
	&:hover {
		color: oColorsByName('black-80');

		&:after {
			@include oIconsContent(
				'arrow-right',
				oColorsByName('black-80'),
				20,
				$iconset-version: 1
			);
		}
	}
}

.article-grid--centered .concept-list__list-item {
	@include oGridRespondTo(L) {
		float: left;
		margin-right: 20px;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:750");