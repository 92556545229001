@use "sass:meta" as ---lcnhxkjbet;@import '@financial-times/o-typography/main';
@import '@financial-times/o-icons/main';
@import '@financial-times/o-spacing/main';
@include oSpacing();
@import '@financial-times/o-colors/main';
@include oColors();
@import '@financial-times/o-forms/main';
@include oForms();

.o-overlay--pro-features-config-modal {
	.o-overlay__content {
		padding: 0;
	}
}

.pro-features-config {
	margin: oSpacingByIncrement(8);

	&-icon.o-overlay__close {
		position: absolute;
		top: 0;
		right: 0;
		margin: oSpacingByIncrement(5);
		padding: 0;

		cursor: pointer;
		@include oIconsContent($icon-name: 'cross', $size: 40);

		&::after {
			content: none;
		}
	}

	&-title {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		&__main {
			@include oTypographySans(2);
		}
		&__prompt {
			@include oTypographySans(-1);
			color: oColorsByName('black-50');
		}
	}

	&--toggle {
		margin-block: oSpacingByName('s6');
		&__wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: oSpacingByName('s4');
			padding: oSpacingByName('s4');
			background-color: oColorsMix('ft-grey', 'white', 5);

			&-input__label {
				&__main {
					text-transform: uppercase;
					letter-spacing: 1px;
					@include oTypographySans(-2, $weight: 'regular', $line-height: 24px);
					color: oColorsByName('black-70');
				}
			}

			&-input.o-forms-input.o-forms-input--inline {
				display: flex;
				gap: oSpacingByIncrement(2.5);
				margin: 0;

				label {
					margin: 0;
				}
				input:checked + .o-forms-input__label:before {
					background-color: oColorsByName('slate');
				}
				input:checked + .o-forms-input__label:after {
					border-color: oColorsByName('slate');
					background-color: oColorsByName('white');
					@include oIconsContent(
						'tick',
						$size: null,
						$color: oColorsByName('slate'),
						$include-base-styles: false
					);
				}
				.o-forms-input__label:after {
					background-color: oColorsByName('white');
				}
			}
		}

		&-label {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0;
			width: 60%;
			&__main {
				@include oTypographySans(0, $weight: 'semibold');
			}
			&__prompt {
				@include oTypographySans(-1);
				color: oColorsByName('black-50');
			}
		}
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:800");