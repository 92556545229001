@use "sass:meta" as ---lcnhxkjbet;/* stylelint-disable-next-line selector-class-pattern */
.o-overlay--signUpConfirmationOverlay {
	max-width: 700px;

	.o-overlay__title {
		font-weight: bold;
		margin-bottom: 0;
	}

	.sign-up-confirmation-overlay-subtitle {
		padding-bottom: oSpacingByName('s6');
	}

	.o-overlay__heading {
		line-height: oSpacingByName('m12');
	}

	.o-overlay__content {
		padding-top: 0;
	}

	.o-expander {
		display: grid;
		grid-template-areas: 'a b';
	}

	.o-expander__content {
		grid-area: a;
	}

	.o-expander__toggle {
		grid-area: b;
		align-self: end;

		&::after {
			background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1%3Aarrow-down?source=next-article&tint=teal');
		}
	}

	.o-expander--initialized[data-o-expander-shrink-to='height']
		.o-expander__content {
		max-height: 40px;
	}

	.o-forms-input--checkbox {
		.o-forms-input__label {
			padding-top: 0;

			&.newsletter {
				font-weight: bold;
			}
		}

		.o-forms-input__description {
			padding-left: 38px;
			margin-top: 0;
		}
	}

	.o-buttons-group {
		.o-buttons {
			margin-left: auto;
			margin-right: auto;
		}

		@media (min-width: 400px) {
			display: block;
			text-align: center;

			.o-buttons {
				min-width: 150px;
			}

			.o-buttons--secondary {
				margin-left: 20px;
			}
		}
	}
}

/* stylelint-disable-next-line selector-class-pattern */
.o-overlay--signUpAdditionalConfirmationOverlay {
	.sign-up-confirmation-overlay-content {
		padding: oSpacingByName('s6');
		text-align: center;

		a {
			margin-top: oSpacingByName('s6');
		}
	}

	$signup-overlay-icon-size: 40px;
	.o-success-icon {
		width: $signup-overlay-icon-size;
		height: $signup-overlay-icon-size;
		background: oColorsByName('teal-50');
		border-color: oColorsByName('teal-50');
		border: 2px solid oColorsByName('black-40');
		border-radius: 9999px;
		margin: 0 auto;

		&:before {
			display: inline-block;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: 50%;
			background-color: transparent;
			background-image: url(https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1:tick?format=svg&source=next-subscribe&tint=%23FFFFFF%2C%23FFFFFF);
			vertical-align: baseline;
			width: $signup-overlay-icon-size;
			height: $signup-overlay-icon-size;
			content: '';
			color: oColorsByName('white');
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.o-message--sign-up-additional-location {
	position: fixed;
	top: 56px;
	width: 100%;
	background-color: oColorsByName('white');
	z-index: 10;
	display: none;
	animation: fadeIn 0.7s forwards;

	iframe {
		width: 100%;
	}

	.o-message {
		@include oGridRespondTo($from: M) {
			.o-message__container {
				justify-content: center;
			}
		}

		.o-message__content .article__content-sign-up {
			display: none;
			margin-top: oSpacingByName('s6');
			margin-left: oSpacingByName('s6');
			border-bottom: 0;
		}
		.o-message__content-highlight {
			display: block;
			font-size: 20px;
		}

		.o-message__close {
			width: 30px;
			height: 30px;
			top: 16px;
			background-color: oColorsByName('white');
			background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1%3Across?source=next-article&tint=black&format=svg');
		}

		.o-message__container {
			padding: 0;
		}
		@include oGridRespondTo($until: M) {
			.o-message__container {
				justify-content: center;
			}
		}

		.o-message__content {
			&-main {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;

				h2 {
					font-size: 1.3em;
				}

				@include oGridRespondTo($until: S) {
					h2 {
						margin-left: 5vw;
						margin-right: 5vw;
					}
				}

				@include oGridRespondTo($from: S) {
					h2 {
						margin-right: oSpacingByName('s4');
						margin-left: oSpacingByName('s1');
					}
					&::before {
						content: '';
						background-image: url('https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1%3Amail?source=next-article&tint=black&format=svg');
						width: 40px;
						height: 40px;
						display: block;
						position: relative;
					}
				}
				@include oGridRespondTo($from: M) {
					h2 {
						margin-right: oSpacingByName('m12');
					}
				}
			}

			@include oGridRespondTo($from: M) {
				width: 100%;
			}

			@include oGridRespondTo($from: L) {
				width: 80%;
			}

			.o-message__actions .o-message__actions__primary {
				/* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
				@extend .o-buttons;

				/* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
				@extend .o-buttons--big;

				/* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
				@extend .o-buttons--primary;
				min-height: auto;
				padding: 6px 30px;

				@include oGridRespondTo($until: S) {
					margin-left: 5vw;
					margin-bottom: oSpacingByName('s4');
					width: 90vw;
				}
				@include oGridRespondTo($from: S, $until: M) {
					margin-left: oSpacingByName('s3');
					padding: 6px 16px;
				}
			}
		}
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:1050");