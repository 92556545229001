@use "sass:meta" as ---lcnhxkjbet;@import '@financial-times/o-message/main';
@import '@financial-times/o-spacing/main';

// Include notice message for article content.
@include oMessage(
	$opts: (
		'types': (
			'notice'
		)
	)
);

// Add a custom message state `o-message--alerts-container`
@include oMessageAddState(
	$name: 'instant-alerts-promo',
	$opts: (
		'foreground-color': 'black',
		'background-color': 'white-60'
	),
	$types: (
		'notice'
	)
);

// Apply margin between the message and article content.
.o-message--alerts-container {
	margin-bottom: oSpacingByName('s4');
}

.o-message--alerts-container .instant-alerts-promo-button {
	// o-buttons-icon--notifications doesn't exist so we need to do this way
	// https://github.com/Financial-Times/o-buttons#icons
	@include oButtonsContent(
		$opts: (
			'type': 'secondary',
			'theme': 'mono',
			'icon': 'notifications'
		)
	);
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:999");