@use "sass:meta" as ---lcnhxkjbet;.above-header-advert {
	display: none;
	padding: 0;

	@media (min-width: $ad-medium-breakpoint) {
		display: block;
		line-height: 0;
		text-align: center;
	}
}

:root:not([data-perf-janky^='calm']) {
	.in-article-advert,
	.mobile-advert {
		&.o-ads--empty,
		&[data-o-ads-loaded='false'] {
			height: 0;
			min-height: 0;
			padding: 0;
		}
	}
}

.sidebar-advert {
	width: 100%;
	min-height: 250px;
}

.bottom-advert {
	margin-top: 8px;
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:807");