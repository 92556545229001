@use "sass:meta" as ---lcnhxkjbet;$system-code: 'next-article';

.flourish-disclaimer {
	background-color: oColorsByName('wheat') !important;
	margin: 0 auto;
	margin-bottom: 15px;
	max-width: 1020px;
	display: block;
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:1052");