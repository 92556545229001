@use "sass:meta" as ---lcnhxkjbet;.n-content-tag--author {
	@include oEditorialTypographyTag($type: 'author');
	border: 0;
	text-decoration: none;
}

.n-content-tag--with-follow {
	margin-right: 10px;
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:962");