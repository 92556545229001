@use "sass:meta" as ---lcnhxkjbet;//TODO: Putting all the teaser-related CSS here.
//Can tidy up/put in the corrrect places once everything else is deleted

//below the fold:
@import '@financial-times/o-teaser/main';

$o-teaser-collection-is-silent: false;
@import '@financial-times/o-teaser-collection/main';

@include oTeaser(
	$opts: (
		'elements': (
			'default',
			'images',
			'timestamp',
			'syndication',
			'promoted'
		),
		'themes': (
			'standard',
			'small',
			'audio',
			'large',
			'hero',
			'package'
		)
	)
);

$o-labels-is-silent: false;
@import '@financial-times/o-labels/main';

.o-labels {
	@include oTypographySans($scale: -2, $weight: 'semibold');
}

// o-labels--live was removed from o-labels 4.x
// This rule makes that change backwards compatible
.o-labels--live {
	background-color: oColorsByName('crimson');
	color: oColorsByName('white');
}

.standalone-teaser-heading {
	@include oTypographySans($scale: 1);
	margin: 0 0 20px;
	font-weight: bold;

	&:after {
		content: '';
		display: block;
		width: 60px;
		margin-top: 5px;
		border-bottom: 4px solid oColorsByName('black');
		position: absolute;
		z-index: 1;
	}
}

.article__aside .o-teaser-collection {
	margin-top: 24px;
}

.n-content-related-box .o-teaser {
	margin: 8px 0;
}

.n-content-related-box .standalone-teaser-heading {
	margin-bottom: 0;
}

.n-content-related-box--no-border {
	border: 0;
	margin-top: 0;
	padding: 0;
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:707");