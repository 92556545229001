@use "sass:meta" as ---lcnhxkjbet;@import '@financial-times/o-normalise/main';
@import '@financial-times/x-gift-article/src/main';

#share-article-modal {
	position: absolute;
	.o-buttons.o-buttons--big.o-buttons--primary.share-article-dialog__create-link-button {
		/* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
		@extend .o-buttons--big;
	}
	@include oGridRespondTo($from: L) {
		left: 100%;
		top: 0;
		margin-left: oSpacingByName('s4');
	}
}

.advanced-sharing-first-use-tooltip {
	width: 130px;
	white-space: normal;

	p {
		margin: 0;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:417");