@use "sass:meta" as ---lcnhxkjbet;$system-code: 'next-article';

@import '@financial-times/o-table/main';
@import '@financial-times/o-typography/main';
@import '@financial-times/o-icons/main';
@include oIcons();

.onward-journey__free-to-read {
	font-family: MetricWeb, sans-serif;
	background-color: #f7e6d7; //oColorsMix('wheat', 'paper', 60)
	padding: 36px;
	font-size: 20px;
	line-height: 24px;

	@include oGridRespondTo($until: M) {
		padding: 24px;
		font-size: 18px;
		margin-bottom: 24px;
	}
}

.onward-journey__free-to-read-header {
	font-family: MetricWeb, sans-serif;
	font-weight: 600;
	font-size: 24px;
	line-height: 24px;
	margin-top: 0;

	@include oGridRespondTo($until: M) {
		font-size: 20px;
		line-height: 24px;
	}
}

.onward-journey__free-to-read-emphasis {
	font-weight: 600;
}

.onward-journey__free-to-read-content {
	margin-top: 20px;

	@include oGridRespondTo($until: M) {
		margin-top: 16px;
	}
}

.onward-journey-toggle-container {
	.onward-journey__collection-button {
		font-family: 'MetricWeb';
		@include oTypographySans($scale: 0, $weight: 'regular');
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
		border-radius: 4px;
		color: oColorsByName('black');
		border-color: oColorsByName('black-10');
		background: oColorsByName('black-10');
		padding: 6px 16px;
		margin-right: 10px;

		&:hover {
			color: oColorsByName('black');
			border-color: oColorsByName('black-10');
			background: oColorsByName('black-10');
		}

		&:active {
			color: oColorsByName('white');
			border-color: oColorsByName('black-70');
			background: oColorsByName('black-70');
		}

		&__selected {
			color: oColorsByName('white');
			border-color: oColorsByName('black-70');
			background: oColorsByName('black-70');

			&:hover {
				color: oColorsByName('white');
				border-color: oColorsByName('black-70');
				background: oColorsByName('black-70');
			}

			&:focus {
				color: oColorsByName('white');
				border-color: oColorsByName('black-70');
				background: oColorsByName('black-70');
			}

			&:focus-visible {
				box-shadow: 0 0 0 4px #ffffff, 0 0 0 8px #4d4845;
				color: oColorsByName('white');
				border-color: oColorsByName('black-70');
				background: oColorsByName('black-70');
			}
		}
	}

	.o-table.o-table--free-to-read {
		width: fit-content;
		td {
			padding: 0;
			border: none; /* stylelint-disable-line declaration-property-value-disallowed-list */
		}
	}
}

.o-header__subnav-item:not(:first-child) {
	padding-left: oSpacingByName('s2') !important;
}

.o-header__subnav-link:after {
	position: relative;

	&:hover {
		background-color: inherit;
	}
}

.onward-journey-toggle-container {
	padding-bottom: 24px;
	border-bottom: 1px solid #ccc1b7;
	margin-bottom: 24px;
}

.external-link__survey {
	display: flex;
	align-items: center;
	margin-top: 32px;
}

.external-link__icon {
	@include oIconsContent(
		$icon-name: 'outside-page',
		$size: 24,
		$color: #0d7680
	);
	border: 0;
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:1152");