@use "sass:meta" as ---lcnhxkjbet;@page {
	size: auto;

	// Set height of print header & footer *content* as well as lateral margins
	margin: 10mm;
}

// Print overrides
@media print {
	html,
	body {
		margin: 0;
		padding: 0;
	}

	html {
		margin: 5mm 15mm;
	}

	// Try to group related content
	h2,
	h3 {
		break-after: avoid-page;
	}

	p {
		page-break-inside: avoid;
		break-inside: avoid;
	}

	// Fixes firefox print bug
	.article-grid {
		display: block;
	}

	.o-message__instant-alerts-promo,
	.o-header,
	.o-header__nav, //these additional header classes are needed for Firefox
	.o-header--sticky,
	.o-header__top-column--left,
	.o-header__top-column--right,
	.o-header__container,
	.o-header__drawer,
	.core .o-header__search,
	.o-topper__visual, //remove topper images
	.o-topper__background,
	.o-topper__headshot,
	.n-myft-ui, //remove myft buttons
	.o-teaser,
	.n-content-related-box--no-border, //related article inline
	.n-content-related,
	.n-content-recommended,
	.n-content-recommended--single-story, //recommended single article inline
	.n-messaging-slot,
	.share-nav, //share buttons
	.component-share,
	.article__right, //RHR
	.article__right-bottom,
	.o-teaser-collection, //related stuff at bottom
	.comments-container,
	.article__tags,
	.article-tools__buttons, //hide button but keep copyright
	.article-comment-count,
	.article__media, //audio player for podcast
	.follow-tags,
	.package-mobile-bleed,
	.related-content,
	.read-next-bottom,
	.event-promo,
	.onward-journey,
	.promoted-content__container, //native ad slot at the bottom of article content
	.o-footer,
	.o-footer__container, //also needed for firefox
	.o-teaser-collection,
	.loading-indicator {
		display: none !important;
	}

	//stops Chrome printing extra pages
	.o-grid-container {
		padding: 0;
	}

	[data-o-grid-colspan] {
		width: 100%;
		max-width: initial;
	}

	.o-grid-row {
		display: inherit;
	}

	.o-header__top-logo {
		background-image: none;

		&:after {
			content: url('https://www.ft.com/__origami/service/image/v2/images/raw/ftlogo:brand-ft-masthead?source=o-header&tint=%23505050,%23505050&format=svg');
			display: block;
		}
	}

	.n-layout {
		min-height: 0;
	}

	.o-topper {
		margin: 0;
	}

	//stop graphics from breaking onto multiple pages
	.n-content-picture {
		page-break-inside: avoid;
	}

	//stop image placeholder reserving space, because we are hiding the image inside

	.n-content-image {
		margin: 0 auto;
	}

	.n-content-related-box {
		max-width: auto;
	}

	// NOTE - horrible! removes the article body's offset, otherwise IE8 leaves it in when printing
	[data-o-grid-colspan~='XLoffset1'],
	.article__header-inner,
	.article__main {
		margin: 0 auto;

		> *:first-child {
			margin-left: 0;
			padding-left: 0;
		}
	}

	//Reduce the font sizes and line heights
	.n-content-body > p {
		margin: 0 0 1em;
		font-size: 12pt;
	}

	.topper__primary-theme,
	.topper__primary-theme *,
	.article-info,
	.article-info * {
		font-size: 11pt !important;
		line-height: 1 !important;
	}

	.article-info__byline {
		color: inherit;
		font-weight: 400;
	}

	.o-topper__headline {
		font-size: 16pt !important;
		margin-bottom: 0.25em !important;
		color: oColorsByName('black') !important;
		&:after {
			border-bottom: 6px solid oColorsByName('black') !important;
		}
	}

	.o-topper__standfirst {
		font-size: 11pt !important;
		margin-bottom: 0.75em !important;
		color: oColorsByName('black') !important;
	}

	.magnet-cta {
		display: none;
	}

	//Remove background colour of toppers
	.o-topper__content {
		background-color: transparent !important;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:1153");