@use "sass:meta" as ---lcnhxkjbet;@import './byline';

// stet but is referred to in cp ui PodcastBody
.article__media {
	margin-bottom: 20px;

	audio,
	video {
		width: 100%;
	}
}

.o-ads {
	@include oGridRespondTo($from: S, $until: L) {
		clear: left;
	}
}

// Ideally article markup would be updated to use the `o-editorial-typography`
// class: https://registry.origami.ft.com/components/o-editorial-typography
.n-content-body > .n-content-blockquote,
.n-content-body > p {
	@include oTypographySans(
		$scale: (
			default: 1,
			XL: 2
		),
		$line-height: 1.6,
		$include-font-family: false
	);
}

//Give a background to responsive graphics so they appear above sticky elements
.n-content-picture {
	background-color: oColorsByUsecase(page, background);
}

//Full bleed elements - amend the positioning to match grid
.n-content-layout[data-layout-width='full-grid'] {
	@include oGridRespondTo(L) {
		left: calc(50% + 120px);
		z-index: 2; // keep above share button & ads
	}
}

// Full bleed elements on centered template
.article-grid--centered {
	.n-content-layout[data-layout-width='full-grid'] {
		@include oGridRespondTo(L) {
			left: 50%;
			z-index: 2; // keep above share button & ads
		}
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:949");