@use "sass:meta" as ---lcnhxkjbet;.article-classifier__gap:after {
	content: ' ';
	margin-right: 0.11em;
}

.article-classifier {
	@include oTypographySans($scale: -2, $weight: 'semibold');

	vertical-align: 5%;
	padding: 0.1em 0.4em 0.14em;
	background-color: oColorsByName('black-80');
	color: oColorsByName('white');

	.article-headline & {
		vertical-align: 30%;
	}

	.article-classifier__gap ~ & {
		// overrides oTypographySansBold's setting
		line-height: 15px;
		font-size: 15px;
	}
}

;@include ---lcnhxkjbet.load-css("sass-embedded-legacy-load-done:953");